//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    companyId: { type: Number, default: 0 },
    project: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      loading: false,
      isEnableAuth: this.project?.homepage?.credentials?.is_public ?? false,
      isRequiredPrivatePage: this.project?.homepage?.credentials?.is_required_private_page ?? false,
      form: this.$form.createForm(this),
    }
  },

  computed: {
    formItemLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: { xs: { span: 16 }, sm: { span: 18 }, lg: { span: 18 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: {
          xs: { span: 16, offset: 8 },
          sm: { span: 20, offset: 3 },
          lg: { span: 10, offset: 3 }
        }
      };
    },
    formItemOptions() {
      const isRequiredPassword = this.isEnableAuth || this.isRequiredPrivatePage
      return {
        password: {
          initialValue: this.project?.homepage?.credentials?.password || '',
          rules: [{ required: isRequiredPassword, message: this.$t('Please input the password') }]
        },
      };
    },
  },
  methods: {
    handleSubmitForm() {
      this.form.validateFieldsAndScroll((errors, values) => {
        if (!errors) {
          const { password } = values
          const payload = {
            homepage: {
              credentials: {
                is_public: this.isEnableAuth,
                password: password,
                is_required_private_page: this.isRequiredPrivatePage
              }
            }
          }
          this.updateProject(payload)
        }
      })
    },
    async updateProject(payload) {
      try {
        this.loading = true;
        await this.$s.api.project.editProjectById(this.companyId, this.project?.id, payload);
        this.$notification.success({ message: this.$t('This project has been updated') });
      } catch (error) {
        this.$notification.error({
          message: `${this.$t('This project update failed')}: ${this.$t(error.message)}`
        });
      } finally {
        this.loading = false;
      }
    },
    changeEnableAuth({ target }) {
      this.isEnableAuth = target.checked
      if (!this.isEnableAuth) {
        this.isRequiredPrivatePage = false
      }
      this.$nextTick(() => {
        this.form.validateFields(['password'], { force: true });
      });
    },
    changeRequiredPrivatePage({ target }) {
      this.isRequiredPrivatePage = target.checked
      this.$nextTick(() => {
        this.form.validateFields(['password'], { force: true });
      });
    }
  }
}
