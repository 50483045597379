var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    { attrs: { form: _vm.form } },
    [
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "mb-sm", attrs: { label: _vm.$t("Password") } },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["password", _vm.formItemOptions.password],
                expression: "['password', formItemOptions.password]"
              }
            ],
            attrs: {
              size: "large",
              disabled: !_vm.isEnableAuth && !_vm.isRequiredPrivatePage
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "mb-none", attrs: { colon: false } },
          "a-form-item",
          _vm.formTailLayout,
          false
        ),
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.isEnableAuth },
              on: { change: _vm.changeEnableAuth }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Enable authentication")) + " ")]
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { staticClass: "mb-none", attrs: { colon: false } },
          "a-form-item",
          _vm.formTailLayout,
          false
        ),
        [
          _c(
            "a-checkbox",
            {
              attrs: {
                checked: _vm.isRequiredPrivatePage,
                disabled: !_vm.isEnableAuth
              },
              on: { change: _vm.changeRequiredPrivatePage }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("Required only for private page")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { colon: false } },
          "a-form-item",
          _vm.formTailLayout,
          false
        ),
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }